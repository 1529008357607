.sidebar {
    --sidebar-space: var(--s0);

    display: flex;
    flex-wrap: wrap;
    gap: var(--sidebar-space);
}

.left > :first-child {
    flex-basis: var(--side-width, inherit);
    flex-grow: 1;
}
.left > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--content-min-width, 50%);
}

.right > :last-child {
    flex-basis: var(--side-width, inherit);
    flex-grow: 1;
}
.right > :first-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--content-min-width, 50%);
}
