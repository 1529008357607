.Sidebar {
    padding: 1.5em 1.5em 3em;
    margin-top: 2em;
    background-color: var(--gray-lightest);
}

.Header {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1em;
    color: var(--brand-primary);
}

.Item {
    margin: 0.75em 0;
    color: var(--black);
    font-size: 1.15em;
    font-weight: 700;
    padding-bottom: 0.5em;
    border-bottom: white solid 4px;

    & > a {
        display: inline-flex;
        align-items: center;

        &:hover {
            color: var(--brand-primary);
        }
    }
}

.SocialItem {
    composes: Item;

    display: flex;
    align-items: center;

    color: var(--brand-primary);

    & > .Label ~ a {
        padding: 0 0.5em;
    }
}

.Label {
}
