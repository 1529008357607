@custom-media --two-columns (width > 360px);
@custom-media --three-columns (width > 560px);
@custom-media --four-columns (width > 780px);

.ItemSet {
    display: flex;
    flex-wrap: wrap;
}

.Item {
    flex: 100%;
    padding: 2em;
    box-sizing: border-box;
    display: flex;

    @media (--two-columns) {
        flex: 50%;
        max-width: 50%;
    }
    @media (--three-columns) {
        flex: 33.3%;
        max-width: 33.3%;
    }
    @media (--four-columns) {
        flex: 25%;
        max-width: 25%;
    }
}

.Logo {
    margin: auto;
    max-width: 100%;
}
