.icon {
    width: 0.75em;
    height: 0.75em;
}

.with {
    display: inline-flex;
    align-items: baseline;
    gap: 1rem;
}
