.TagActive {
    white-space: break-spaces;
    text-align: center;
    background-color: var(--danger);
    color: white;

    & > a:hover {
        color: white;
    }

    & > a:after {
        display: none;
    }
}

.TagExpired {
    white-space: break-spaces;
    text-align: center;
    background-color: var(--warning);
    color: white;

    & > a:hover {
        color: white;
    }

    & > a:after {
        display: none;
    }
}

.Banner {
    background-color: var(--danger);
    padding: 0.8em 1em;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    color: white;

    & a {
        color: white;
    }
}

.Headline {
    margin: 1em 0;
    font-weight: 700;
    font-size: 1.5em;
}

.link {
    text-decoration: underline;
}
