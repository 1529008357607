.legacy-container input {
    border-radius: 0;
    padding-left: 10px;
    border: 1px solid var(--gray-lighter);
    background-color: white;
    box-sizing: border-box;
    line-height: 1;
    outline-offset: inherit;
}
.legacy-container input:not([type='checkbox']):not([type='radio']) {
    width: 100%;
    height: 40px;
}
.legacy-container input[type='file'] {
    cursor: pointer;
}
.legacy-container textarea {
    display: block;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 75px;
    padding: 0.5em;
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid var(--gray-lighter);
}
.legacy-container label {
    display: block;
    margin: 0.5em 0;
}
.legacy-container label > textarea,
.legacy-container label > input:not([type='checkbox']):not([type='radio']) {
    margin: 0.5em 0;
}

.legacy-container label > input[type='radio'],
.legacy-container label > input[type='checkbox'] {
    margin-right: 0.5em;
}
.legacy-container input[type='checkbox'] + label,
.legacy-container input[type='radio'] + label {
    display: inline;
    font-weight: normal;
}

.legacy-container fieldset {
    border: 1px solid var(--gray-lighter);
    margin: 1em 0;
}
.legacy-container fieldset > legend {
    font-weight: 700;
}
.legacy-container fieldset > legend:before,
.legacy-container fieldset > legend:after {
    content: '\00a0';
}

.legacy-container form[data-has-errors='false'] :is(input, textarea) + span[data-error-message] {
    display: none;
}

.legacy-container form[data-has-errors='true'] :is(input:invalid, textarea:invalid) {
    border-color: var(--brand-secondary);
}

.legacy-container form[data-has-errors='true'] :is(input:invalid, textarea:invalid) + span[data-error-message] {
    display: block;
    color: var(--brand-secondary);
}
