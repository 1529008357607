:root {
    --ratio: 1.5;

    --s-5: calc(var(--s-4) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-1: calc(var(--s0) / var(--ratio));
    --s0: 1rem;
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));

    --measure: 60ch;

    --grid-width: 1000px;
    /* TODO change --grid-width to --max-page-width */
    --max-page-width: 1000px;

    --main-nav-height: 60px;

    --radius-infinite: 9999px;

    --color-blue-light: #77bace;
    --color-blue: #245eac;
    --color-blue-dark: #14182f;
    --color-blue-darker: #010118;
    --color-red: #e5252f;

    /* Via: https://www.joshwcomeau.com/shadow-palette/ */
    --shadow-color: 0deg 0% 0%;
    --shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.1),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.1),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.1);
    --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.11),
        0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.11),
        2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.11),
        5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.11);
    --shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.1),
        1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.1),
        2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.1),
        4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.1),
        7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.1),
        11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.1),
        17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.1),
        25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.1);
}

@media only screen and (min-width: 990px) {
    :root {
        --main-nav-height: 75px;
    }
}
