.Container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Segment {
    composes: import Primary from '../../components/button/Button.module.css';
    margin: 0.15em;
    border-radius: inherit;
}

.Segment--Active {
    composes: Segment;
    composes: import Primary--Active from '../../components/button/Button.module.css';
}

.Disabled {
    composes: Segment;
    composes: import Primary--Disabled from '../../components/button/Button.module.css';
}
