.Metadata {
    --border-color: var(--gray-lightest);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    font-size: 0.85em;
}

.Entry {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 50%;
    box-sizing: border-box;
    min-height: 3em;
    padding: 0.25em;
    border-bottom: 2px solid var(--border-color);
    text-transform: uppercase;
}

.Horizontal {
    flex-direction: row;
    align-items: center;

    & .Description {
        margin-left: 0.25em;
    }
}

.Term {
    color: var(--primary);
    font-weight: 700;
    white-space: nowrap;
}

.Description {
    flex: 1 0;
    margin-left: 0;
    color: var(--gray-darker);
    font-weight: 700;
}

.Toggleable {
    cursor: pointer;
}

.ShareEntryItem {
    padding: 0 0.5em;
}
