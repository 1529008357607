.Wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
    max-height: 95vh;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.GridContainer {
    overflow: auto;
    max-height: calc(95vh - 200px);
    width: 100%;
}

.Grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 320px;
}

.GridHeader {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
}

.GridBody {
    display: flex;
    flex-direction: column;
}

.Row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
}

.Cell {
    flex: 1;
}

.CellContent {
    padding: 0.75em; /* This needs to be applied to the cell content and not in the Cell because the Cell is a flex item, so padding on it causes a column misalignment */
}

.HeaderCell {
    flex: 1;
    padding: 1em;
    font-weight: bold;
    text-align: center;
}

.DangerLevelCell {
    flex: 1;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.Swatch {
    flex: 0 0 15px;
    border-right: 1px solid #555;
    padding: 0;
}

.Title {
    text-align: center;
    margin: 0 1.5em 1em;
}

.Header {
    padding: 0.5em 1em;
}

.Subheader {
    margin: 0 auto 1em;
}

.Icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.CloseButton {
    position: absolute;
    right: 0;
    top: 0;
}

.SpanColumns {
    flex: 3;
}

/* Mobile styles; the breakpoint is arbitrary, just what looked good */
@media (max-width: 900px) {
    .GridHeader {
        display: none;
    }

    .Row {
        flex-direction: column;
        margin-bottom: 1.5em;
        border-bottom: 1px solid transparent;
    }

    .Cell {
        width: 100%;
        padding: 0.5em 1em;
    }

    .CellContent {
        padding: 0;
    }

    .DangerLevelCell {
        width: 100%;
        padding: 0.5em 1em;
    }

    .Swatch {
        width: 100%;
        height: 8px;
        border-right: none;
    }
}
