.Container {
    display: flex;

    & :global(.DayPickerInput) {
        flex: 1;
    }

    & :global(.DayPickerInput-OverlayWrapper) {
        z-index: 1000;
    }
}

.Input {
    composes: import Input from '../Controls.module.css';

    cursor: pointer;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
}
