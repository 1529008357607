.Dropdown {
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    z-index: 9;
    overflow-y: auto;
}

.Option {
    composes: import Common from '../Controls.module.css';

    background-color: var(--component-primary-bg-inactive);
    cursor: pointer;
    color: white;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-color: var(--component-primary-bg-hover);
    }
}

.Option--Active {
    composes: Option;

    &,
    &:hover {
        background-color: var(--component-primary-bg-active);
    }
}
