/* https://www.joshwcomeau.com/css/full-bleed */

.bleeder {
    --bleeder-padding-space: var(--s1);
    --bleeder-width: var(--measure);

    display: grid;
    grid-template-columns:
        1fr
        min(var(--bleeder-width), calc(100% - 2 * var(--bleeder-padding-space)))
        1fr;
    box-sizing: content-box;
}

.bleeder > * {
    grid-column: 2;
}

.full > * {
    grid-column: 1 / -1;
}
