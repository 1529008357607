@custom-media --without-media (width < 500px);

.Container {
    display: flex;
    color: var(--gray);
    margin: 5px;
    padding: 5px;
    line-height: 1.5em;
}

.Content {
    flex: 1 1;
}

.Header {
    font-weight: bold;
}

.Name {
    color: var(--brand-primary);
}

.Title {
    margin-left: 5px;
    color: var(--gray-dark);
}

.Media {
    height: 100%;
    margin-right: 10px;

    @media (--without-media) {
        display: none;
    }
}

.Address {
    & > * {
        display: block;
    }
}
