.center {
    --max-width: var(--measure);

    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width);
    padding-right: var(--gutters, inherit);
    padding-left: var(--gutters, inherit);
}

.intrinsic {
    display: flex;
    flex-direction: column;
    align-items: center;
}
