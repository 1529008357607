.Common {
    width: 100%;
    height: 40px;
    border-radius: 0;
    padding-left: 10px;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
}

.WithBorder {
    border: 1px solid var(--gray-lighter);
}

.Native {
    appearance: none;
}

.Input {
    composes: Native;
    composes: Common;
}

.WithIcon {
    background-position: 99.25% center;
    background-repeat: no-repeat;
    background-size: 24px;
}

.CloseState {
    composes: WithIcon;

    background-image: url('../icons/expand-more.svg');
}

.Holder {
    color: var(--gray-dark);
    line-height: 40px;
    margin-right: 28px;
}

.Placeholder {
    composes: Holder;
}

.Valueholder {
    composes: Holder;

    color: inherit;
}

.Holder--Content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Toggle {
    composes: Input;
    composes: Holder;

    margin: 0;
    font-weight: bold;
}

.ToggleActive {
    color: initial;
}
