[data-theme-variant='dark'],
[data-theme-variant='dark'] * {
    background-color: var(--color-blue-dark);
    color: white;
    border-color: var(--color-blue-light);
}

[data-theme-variant='dark'] a[href],
[data-theme-variant='dark'] hr {
    color: var(--color-blue-light);
}

[data-theme-variant='dark'] button {
    background-color: var(--color-red);
    border-color: var(--color-red);
}

button[data-theme-variant='transparent'] {
    background-color: transparent;
    border-color: white;
}

.no-hover-underline:hover {
    text-decoration: none;
}
