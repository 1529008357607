.Container {
    display: flex;
    color: white;
    background-color: black;
    line-height: 2.5em;
    margin-left: -1em;
    position: relative;
    text-indent: 0;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2.5em;
        border-color: color-mod(var(--brand-secondary) blackness(50%))
            color-mod(var(--brand-secondary) blackness(50%)) transparent transparent;
        border-style: solid;
        border-width: 8px;
    }
}

.Content {
    padding: 0 1em;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
}

.Caption {
    composes: Content;
    background-color: var(--brand-secondary);
    flex: 25%;
}

.Title {
    composes: Content;
    flex: 75%;
}
