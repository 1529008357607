.stack,
.recursive {
    --stack-space: var(--s0);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.stack > *,
.recursive * {
    margin-top: 0;
    margin-bottom: 0;
}

.stack > * + *,
.recursive * + * {
    margin-top: var(--stack-space);
}
