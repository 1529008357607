.Wrapper > input {
    padding-right: 3em;
}
.Wrapper > button {
    padding: 0;
    margin-left: -3em;
    max-width: 3em;
    border: none;
    background-color: transparent;
    font-weight: bold;
}
