input {
    border-style: solid;
    padding: var(--s-1);
}

input:where([type='email'], [type='search']) {
    border-radius: 777px;
}

::placeholder {
    color: var(--gray);
    opacity: 1;
}
