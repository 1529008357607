.Form {
    composes: import RestrictFullPageWidth from 'components/page/Page.module.css';

    width: 100%;
    box-sizing: border-box;
}

.Fieldset {
    min-width: 0;
    border: none;
    padding: 0;
    margin: 0;
}

.Legend {
    color: white;
    font-size: 2em;
    font-weight: 700;
}

.ControlSet {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;

    & > .Control {
        margin: 0.25em;
    }
}

.ControlSet--Horizontal {
    composes: ControlSet;

    flex-direction: row;
}

.Control {
    flex: 1 0;
    min-width: 125px;
    font-weight: 700;
    position: relative;

    & input {
        font-weight: 700;
    }

    & > svg {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;

        & path[fill='black'] {
            fill: var(--primary);
        }
    }
}

.Control--Horizontal {
    composes: Control;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.Border {
    border: 1px solid var(--gray-lighter);

    & input {
        border: none;
    }
}
