html {
    background-color: white;
    color-adjust: exact;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-size: 15px;
    color: var(--gray-darker);
    box-sizing: border-box;
}

@media screen and (max-width: 680px) {
    body {
        font-size: 13px;
    }
}

@media screen and (max-width: 480px) {
    body {
        font-size: 12px;
    }
}

:is(h1, h2, h3, h4, h5, h6) a {
    color: inherit;
}

:is(h1, h2, h3, h4, h5, h6) a:hover {
    color: var(--primary);
}

:is(h1, h2, h3, h4, h5, h6) a:not([href^='#'])::after {
    /* \00a0 == &nbsp; */
    content: '\00a0›';
    color: var(--primary);
}

:is(h1, h2, h3, h4, h5, h6) > small {
    display: block;
    color: var(--gray);
    font-size: 65%;
}

a {
    text-decoration: none;
    color: var(--primary);
}

/* More info and ideas here: https://davidwalsh.name/external-links-css */
/* A summer project ;) */
/*
a[target="_blank"]:not(:empty):after {
    content: " ↗";
    color: var(--gray);
    font-size: 0.75em;
}
*/

img {
    max-width: 100%;
    /* https://stackoverflow.com/questions/14053952/why-is-there-padding-at-the-bottom-of-my-image */
    vertical-align: bottom;
}

li::marker {
    color: var(--primary);
}

/* DESCRIPTION LIST */
dt {
    font-weight: 700;
}

/* PRINT */
@media print {
    body {
        font-size: 12px;
    }

    img {
        scale: 0.85;
    }

    a:after {
        content: ' (' attr(href) ')';
    }

    details:not([open]) {
        display: none;
    }
}

/* BUTTON */
@media print {
    button {
        display: none;
    }
}

button {
    border-radius: 0;
}
button[disabled],
input[disabled],
fieldset[disabled] button,
fieldset[disabled] input {
    opacity: 0.65;
    pointer-events: none;
    cursor: not-allowed;
}

/* OEMBED */
div[data-oembed] {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0;
    height: 0;
}

div[data-oembed] iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

iframe {
    width: 100%;
    height: 500px;
    border: none;
}
