.skip-link {
    display: inline-block;
    padding: 0.7rem 1rem 0.5rem 1rem;
    background: var(--color-blue-light);
    color: var(--color-blue-dark);
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 1rem;
    left: 1rem;
}
