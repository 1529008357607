.Avatar {
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: auto;
    background-color: var(--primary);
    border-radius: 30px;
    color: white;
    text-align: left;
    font-size: 1em;

    & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &:after {
        content: attr(data-initials);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        display: inline-block;
    }
}

.Initials {
    composes: Avatar;
    & > img {
        display: none;
    }
}

.Small {
    font-size: 0.75em;
}
