body {
    font-family: 'DM Sans', sans-serif;
}

h2,
.h2 {
    font-size: 1.5rem;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:where([href^='tel'], [href^='mailto'])::before {
    font-style: italic;
    white-space: nowrap;
}

a[href^='tel']::before {
    content: 't. ';
}

/* a[href^='mailto']::before {
    content: 'e. ';
}

a[lang^='fr'][href^='mailto']::before {
    content: 'c. ';
} */

address {
    font-style: inherit;
}

/* TODO: Moved from global.css as it was applied everywhere. Look at perhaps moving this back to a global style. */
.button {
    padding: var(--s-1) var(--s1);
    border-radius: 777px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
}

.rounded {
    color: white !important;
    border-radius: var(--radius-infinite);
    background: var(--color-red);
    padding: var(--s-1) var(--s1);
    align-self: flex-start;
}
