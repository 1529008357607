.Container {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    color: var(--gray-darker);
    background-color: white;
    opacity: 0.75;
    font-size: 0.75rem;
    cursor: pointer;
    gap: 0.5em;
    overflow: hidden;

    &::before {
        content: '';
    }

    &::after {
        content: 'i';
        display: inline-block;
        font-weight: bold;
        color: white;
        background-color: var(--gray-darker);
        text-align: center;
        width: 2ch;
    }
}

.Label {
    font-weight: initial;
    user-select: none;
}

.Content {
    margin: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Collapsed:not(:hover) {
    background-color: transparent;

    & > * {
        visibility: hidden;
    }
}

.Top {
    top: 0;
    bottom: auto;
}
