.switcher {
    --switcher-space: var(--s1);
    --threshold: var(--measure);

    display: flex;
    flex-wrap: wrap;
    gap: var(--switcher-space);
}

.switcher > * {
    flex-grow: 1;
    flex-basis: calc((var(--threshold, 30rem) - 100%) * 999);
}

.switcher[data-limit='2'] > :nth-last-child(n + 3),
.switcher[data-limit='2'] > :nth-last-child(n + 3) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='3'] > :nth-last-child(n + 4),
.switcher[data-limit='3'] > :nth-last-child(n + 4) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='4'] > :nth-last-child(n + 5),
.switcher[data-limit='4'] > :nth-last-child(n + 5) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='5'] > :nth-last-child(n + 6),
.switcher[data-limit='5'] > :nth-last-child(n + 6) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='6'] > :nth-last-child(n + 7),
.switcher[data-limit='6'] > :nth-last-child(n + 7) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='7'] > :nth-last-child(n + 8),
.switcher[data-limit='7'] > :nth-last-child(n + 8) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='8'] > :nth-last-child(n + 9),
.switcher[data-limit='8'] > :nth-last-child(n + 9) ~ * {
    flex-basis: 100%;
}
.switcher[data-limit='9'] > :nth-last-child(n + 10),
.switcher[data-limit='9'] > :nth-last-child(n + 10) ~ * {
    flex-basis: 100%;
}
