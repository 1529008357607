.block-img > img {
    max-width: 100%;
}

.float-left {
    float: left;
    margin-right: 1em !important;
}

.float-right {
    float: right;
    margin-left: 1em !important;
}

.primary-text {
    color: var(--primary);
}

.secondary-text {
    color: var(--secondary);
}

.read-more-link a {
    color: var(--gray-light);
    text-transform: uppercase;
    font-size: 0.75em;
}

.read-more-link a:hover {
    color: var(--gray);
}

.callout-info {
    background-color: var(--info);
    color: white;
    display: block;
    padding: 1em 2em;
    margin: 1em auto;
    font-style: italic;
}

.callout-warning {
    background-color: var(--danger);
    color: white;
    display: block;
    padding: 1em 2em;
    margin: 1em auto;
    font-style: italic;
}

.primary-big-button {
    text-align: center;
}

.primary-big-button a {
    display: inline-block;
    min-width: 150px;
    padding: 1em;
    margin: 0.5em 0;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--primary);
    font-size: 1em;
}

.primary-big-button a:hover {
    background-color: color-mod(var(--primary) blackness(15%));
}

.primary-big-button a:after {
    content: '\00a0›';
    color: white;
    font-size: 1.25em;
}

.secondary-big-button {
    text-align: center;
}

.secondary-big-button > a {
    display: inline-block;
    min-width: 150px;
    padding: 1em;
    margin: 0.5em 0;
    font-weight: 700;
    color: white !important; /* TODO avoid using !important. This is temporary as the ACF page will be changing soon enough. */
    text-transform: uppercase;
    text-align: center;
    background-color: var(--brand-secondary);
    font-size: 1em;
}

.secondary-big-button > a:hover {
    background-color: color-mod(var(--brand-secondary) blackness(15%));
}

.secondary-big-button > a:after {
    content: '\00a0›';
    color: white;
    font-size: 1.25em;
}
