@custom-media --as-vertical (width > 600px);

.Container {
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--gray-light);
    margin: 0;

    @media (--as-vertical) {
        flex-direction: column;
    }
}

@media print {
    .Container {
        display: none;
    }
}

.Label {
    width: 6em;
    font-weight: 700;
    font-size: 0.75em;
    text-transform: uppercase;

    @media (--as-vertical) {
        width: auto;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0.25em;
    }
}

.Logo {
    display: block;
    flex: 1 0 auto;
    margin: 0 auto;
    max-height: 50px;
    max-width: 150px;
}
