.Responsive {
    /* From Bootstrap */
    min-height: 0.01%;
    overflow-x: auto;
}

.PageSizeSelector {
    display: flex;
    align-items: center;
    justify-content: center;
}

.FlexContentCell {
    padding-right: 0;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.ControlledRow {
    background-color: var(--gray-lightest);
}

/* Variants */
.Condensed {
    position: relative;

    & td {
        padding: 0.35em;
    }

    & th:not([colspan]) {
        padding: 0.35em;
        text-align: center;
        text-transform: inherit;
    }

    & thead th {
        line-height: initial;
    }
}

.Bordered {
    & tbody tr:hover {
        background-color: var(--gray-lightest);
    }
}

.Featured {
    & td {
        background-color: var(--gray-lightest);
    }

    & tr {
        font-weight: 700;
        border-bottom-color: white;
    }
}
