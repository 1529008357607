.HeaderSet {
    --border-width: 5px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 0;
    margin: 0;
    list-style: none;
}

.Header {
    flex: 1;

    position: relative;
    padding: 1em;
    text-align: center;
    color: var(--black);
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    transition: padding 0.25s ease;

    &:hover {
        background-color: var(--component-bg-hover);
    }
}

@media screen and (max-width: 480px) {
    .Header {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
}

.HeaderisActive {
    background-color: var(--component-active-bg);
    color: white;
    padding-left: 1em;
    padding-right: 1em;

    &:hover {
        background-color: var(--component-active-bg);
    }
}

.HeaderDisabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;

    &:hover {
        background-color: inherit;
    }
}

.HeaderArrow {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 46%;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
    }

    &.HeaderisActive:after {
        background-color: inherit;
    }
}

.Panel {
    margin: 1em;
}

/* Stacked */
.HeaderSetStacked {
    flex-direction: column;
    position: relative;

    & button {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
    }

    & .Header {
        display: none;
    }

    & .HeaderisActive {
        display: block;
        order: -1;
        padding-top: 17px;
    }
}
.HeaderSetExpanded {
    & .Header {
        display: block;
    }
}

/* THEMES */
.HeaderSetCompact {
    & .Header {
        background-color: var(--gray-lighter);
        border-bottom: var(--border-width) solid var(--component-bg);
        z-index: 1;
    }

    & .HeaderisActive {
        background-color: var(--primary);
        border-bottom-color: var(--primary);
    }
}

.HeaderSetLoose {
    background-color: color-mod(var(--primary) lightness(75%));

    & .Header {
        display: inline-block;
        position: relative;
        padding: 1.5em 2.5em;
        font-weight: 700;
        color: white;

        &:after {
            /*
                Do not put border-right, it will break in IE when JS code when
                try measure widths
             */
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: white;
            height: 100%;
            width: 1px;
        }
    }
}
