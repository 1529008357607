.cluster {
    --cluster-space: var(--s1);

    display: flex;
    flex-wrap: wrap;
    gap: var(--cluster-space);
    justify-content: var(--justify, flex-start);
    align-items: var(--align, flex-start);
}

/* HACK: Targets older browsers (e.g. Safari < 14.1) that don't support `display: flex` with `gap`. See corresponding helper function addFlexGapPolyfillClass() in `components/Crystal.js` */
:global(.no-flexbox-gap) .cluster > * + * {
    margin-left: var(--cluster-space);
}
