table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

thead {
    border-bottom: 2px solid var(--gray-lighter);
}
thead > tr:last-child {
    border-bottom: none;
}
thead:after {
    content: '';
    display: block;
}
thead th {
    line-height: 2.5em;
}

tr {
    border-bottom: 2px solid var(--gray-lighter);
}

td {
    color: var(--gray-darker);
    padding: 0.75em;
    vertical-align: middle;
    text-align: left;
    box-sizing: border-box;
}

th {
    color: var(--gray-darker);
    padding: 0 0.75em;
    vertical-align: middle;
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;
    border-left: 2px solid var(--gray-lighter);
    color: var(--gray);
    font-weight: 700;
    text-transform: uppercase;
}

th:first-child {
    border-left: none;
}

tbody th {
    background-color: var(--gray-lightest);
    color: var(--gray-dark);
}
/* tbody's header */
tbody > tr > th:only-child[colspan] {
    line-height: 2em;
    padding: 0 0.5em;
    text-transform: uppercase;
    color: white;
    background-color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
}

caption {
    caption-side: bottom;
    text-align: left;
    color: var(--gray);
    padding: 1em;
}
