@custom-media --header-horizontal (width > 600px);
@custom-media --side-by-side (width > 860px);
@custom-media --full-width (width > 1054px);
@custom-media --medium-width (width > 800px);
@custom-media --small-width (width > 600px);

/* TODO: Should remove the padding in RestrictFullPageWidth */

.RestrictFullPageWidth {
    max-width: 1026px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1em;

    @media (--full-width) {
        padding: 0;
    }
}

.Content {
    composes: RestrictFullPageWidth;
    height: 100%;

    @media (--side-by-side) {
        display: flex;
        flex-direction: row;
    }
}

.Main {
    flex: 1 0 70%;
    height: 100%;
    scroll-margin-block-start: var(--main-nav-height);
    /*  
        HACK: avalanche.ca/youth was overflowing and overflow hidden was not
        working on avalanche.ca/forecasts/archives.
        http://stackoverflow.com/questions/12022288/how-to-keep-a-flex-item-from-overflowing-due-to-its-text
    */
    min-width: 0;

    @media (--full-width) {
        padding: 0;
    }

    @media (--side-by-side) {
        box-sizing: border-box;
    }
}

.Banner {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;
    min-width: 100%;
    height: 300px;
    margin: 0;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media (--medium-width) {
        height: 375px;
    }

    @media (--full-width) {
        height: 450px;
    }
}

.Article {
    width: 100%;
}

.Header {
    composes: RestrictFullPageWidth;
    composes: import MainDecorator from '../misc/Br.module.css';
    composes: import RibbonDecorator from '../misc/Br.module.css';

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;

    position: relative;
    min-height: 5em;
    padding-bottom: 1.5em;
    padding-top: 1.5em;

    & a {
        & svg {
            position: absolute;
            width: 32px;
            height: 32px;
        }
    }

    & > h1 {
        margin: 0;
    }

    @media (--header-horizontal) {
        & > h1 {
            margin: 0.75em 0 0.5em;
        }
    }
}

.Heading[id] {
    scroll-margin-top: 6rem;
}

.Headline {
    font-size: 1rem;
    margin: 1.5em 0;
    font-weight: 700;
    display: block;
    font-style: normal;
}

.Section {
    clear: both;

    & .Headline {
        margin: 1.5em 0;
    }
}

.Aside {
    flex: 1 0 30%;
    box-sizing: border-box;

    @media (--side-by-side) {
        padding-left: 1em;
    }
}

.List {
    text-align: left;
    font-size: 1.25em;
    column-gap: 2em;

    & li {
        margin-bottom: 0.5em;
    }

    @media (--small-width) {
        column-count: 2;
    }
}
