.image-gallery-content.fullscreen .image-gallery-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-gallery-content.fullscreen .image-gallery-image > img {
    max-width: 100vw;
    max-height: 100vh;
    width: unset;
}
