/* Photoswipe captions */
.pswp__custom-caption {
    background: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    color: #fff;
    width: calc(100% - 32px);
    max-width: 400px;
    padding: 2px 8px;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
}
.pswp__custom-caption p {
    margin: var(--s-2) 0;
}
.no-caption {
    display: none;
}

/* Photoswipe custom CSS */
.pswp__top-bar {
    opacity: 1 !important;
}

/* Remove the default margin above p tags in the caption 
(still part of photoswipe custom CSS */
.captionDiv > p {
    margin: 0;
}

/* Manually add some left margin since it appears to be missing */
.pswp__counter {
    margin-left: 10px;
}

.captionDiv a {
    text-decoration: underline;
}
