.box {
    --padding-space: var(--s1);
    --border-width: var(--border-thin);

    padding: var(--padding-space);
    border: var(--border-width) solid;
}

.box * {
    color: inherit;
}

.box[data-border] {
    outline: var(--border-width) solid transparent;
    outline-offset: calc(var(--border-width) * -1);
}
