@custom-media --side-by-side (width > 700px);

.Container {
    margin: 3em 0;

    & h2 {
        text-align: center;
    }
}

.Banner {
    display: block;
    margin: 3em 0;
    min-width: 100%;
}

.Biography {
    display: flex;
    flex-direction: column;

    line-height: 1.5;
    margin: 3em 0;

    @media (--side-by-side) {
        flex-direction: row;

        & > *:nth-child(2) {
            margin-left: 1em;
        }
    }
}

.Avatar {
    /* TODO: Have nicier image and control size in code */
    text-align: center;
    order: 2;

    @media (--side-by-side) {
        order: 1;
        flex: 1 0;
        max-width: 300px;
    }
}

.Content {
    order: 1;

    @media (--side-by-side) {
        flex: 2 0;
        order: 2;
    }
}
