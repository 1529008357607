.Footer {
    padding-top: var(--s3);
    padding-bottom: var(--s1);
}

.IconSet {
    font-size: 2em;
}

.IconSet > * {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-red);
    border-radius: 777px;
    padding: 0.25em;
}

.IconSet * {
    color: var(--color-red);
}

@media only screen and (max-width: 680px) {
    .Primary {
        flex-direction: column-reverse;
    }
    .Primary label {
        text-align: center;
    }
    .Primary .SocalMediaIconSet {
        justify-content: center;
    }
}
