:root {
    --brand-primary: #245eac;
    --primary: #245eac;
    --brand-secondary: #e6252f;
    --secondary: #0f0f0f;

    --black: #0f0f0f;

    --primary-dark: color-mod(#245eac blackness(50%));
    --primary-light: color-mod(#245eac lightness(50%));

    --gray-darkest: color-mod(black lightness(15%));
    --gray-darker: color-mod(black lightness(35%));
    --gray-dark: color-mod(black lightness(50%));
    --gray: color-mod(black lightness(70%));
    --gray-light: color-mod(black lightness(80%));
    --gray-lighter: color-mod(black lightness(90%));
    --gray-lightest: color-mod(black lightness(97%));

    --success: #4caf50;
    --info: #03a9f4;
    --warning: #ffc107;
    --danger: #e6252f;

    --error: #e6252f;

    --component-bg: var(--gray);
    --component-bg-hover: color-mod(var(--gray) blackness(40%));
    --component-bg-active: color-mod(var(--gray) blackness(30%));

    --component-active-bg: var(--primary);
    --component-active-bg-hover: color-mod(var(--primary) blackness(40%));
    --component-active-bg-active: color-mod(var(--primary) blackness(30%));

    --component-primary-bg: var(--primary);
    --component-primary-bg-hover: color-mod(var(--primary) blackness(20%));
    --component-primary-bg-active: color-mod(var(--primary) blackness(50%));
    --component-primary-bg-inactive: var(--primary);

    --component-secondary-bg: var(--secondary);
    --component-secondary-bg-hover: color-mod(var(--secondary) blackness(40%));
    --component-secondary-bg-active: color-mod(var(--secondary) blackness(30%));

    --component-tertiary-bg: var(--brand-secondary);
    --component-tertiary-bg-hover: color-mod(var(--brand-secondary) blackness(40%));
    --component-tertiary-bg-active: color-mod(var(--brand-secondary) blackness(30%));
}
