.text-align\:center {
    text-align: center;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.shadow-elevation-low {
    box-shadow: var(--shadow-elevation-low);
}

.shadow-elevation-medium {
    box-shadow: var(--shadow-elevation-medium);
}

.shadow-elevation-high {
    box-shadow: var(--shadow-elevation-high);
}
