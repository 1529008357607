.grid {
    --grid-space: var(--s1);

    display: grid;
    grid-gap: var(--grid-space);
    grid-template-columns: repeat(auto-fit, minmax(min(var(--minimum, 250px), 100%), 1fr));
}

/* HACK: Targets older browsers (e.g. Safari < 14.1) that don't support `display: flex` with `gap`. Though  grid-gap is supported, it behaves inconsistently across different versions of Safari. See corresponding helper function addFlexGapPolyfillClass() in `components/Crystal.js` */
:global(.no-flexbox-gap) .grid > * {
    margin-right: var(--grid-space);
    margin-bottom: var(--grid-space);
}
:global(.no-flexbox-gap) .grid {
    grid-gap: 0;
}
