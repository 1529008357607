.Main {
    --br-thickness: 8px;

    display: block;
    width: 100%;
    background-color: var(--gray-lightest);
    height: var(--br-thickness);
}

.Ribbon {
    composes: Main;

    &:before {
        content: '';
        display: inline-block;
        float: left;
        background-color: var(--brand-primary);
        width: 50px;
        height: 100%;
    }
}

.MainDecorator {
    --br-thickness: 8px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: var(--br-thickness);
        background-color: var(--gray-lightest);
    }
}
.RibbonDecorator {
    --br-thickness: 8px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: var(--br-thickness);
        background-color: var(--brand-primary);
    }
}
