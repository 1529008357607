.Nav {
    color: white;
    background: transparent;
    height: var(--main-nav-height);
    z-index: 777;
    position: relative;
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--main-nav-height));
}

@supports (-webkit-touch-callout: none) {
    .Nav {
        /* HACK For Safari only (otherwise, this breaks on Android Chrome) */
        height: -webkit-fill-available;
    }
}

.closed {
    display: initial;
}

.Nav a {
    color: var(--color-blue-light);
}

.Brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 230px;
}

.ACFPage {
    background: var(--color-red);
}

.Brand > *:last-child {
    background: var(--color-blue-darker);
    height: var(--main-nav-height);
}

.Brand img {
    height: var(--main-nav-height);
    filter: brightness(0) invert(1);
}

.SettingsMenu a {
    color: white;
    text-decoration: none;
}

.closed .SettingsMenu,
.closed .MainNavigation {
    display: none;
}

.SettingsMenu a[aria-current='true'] {
    border-bottom: 1px solid var(--color-red);
    font-weight: 700;
}

.MainNavigation,
.SettingsMenu {
    background: var(--color-blue-dark);
}

.MainNavigation {
    overflow-y: auto;
    overscroll-behavior-y: contain;
    flex: 1;
    padding: var(--s1);
}

.SettingsMenu {
    padding: var(--s0) var(--s1);
    height: var(--main-nav-height);
}

.Arrow {
    transition: transform 0.2s;
    color: var(--color-red);
}

.Arrow > svg {
    height: 1em;
    width: 1em;
}

.ArrowActive {
    transform: rotate(90deg);
}

.MainHeader {
    font-size: 1.4rem;
    background-color: var(--color-blue-dark);
    padding: var(--s1) 0;
    border-top: 1px solid var(--color-blue-darker);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--s0);
    cursor: pointer;
}

.MainHeader a {
    color: white;
    text-decoration: none;
}

.buttonAppearAsText {
    background-color: transparent;
    border-width: 0;
    color: inherit;
}

.Drawer {
    background-color: var(--color-blue);
    margin: 0 calc(-1 * var(--s1));
    padding: var(--s1);
}

.SubHeader {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: var(--s0);
}

.SubHeader a {
    color: white;
}

.Burger {
    position: relative;
    outline: none;
    display: block;
    border: 0px;
    width: var(--main-nav-height);
    height: var(--main-nav-height);
    padding: 0;
    background: transparent;
    transition: all 150ms ease-out;
    cursor: pointer;
}

.Burger:before,
.Burger:after {
    content: '';
    width: 24px;
    height: 2px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    background: white;
    transition: all 150ms ease-out;
    will-change: transform;
}

.Burger:before {
    transform: translateY(-4px);
}

.Burger:after {
    transform: translateY(4px);
}

.opened .Burger:before {
    transform: translateY(0) rotate(45deg);
}

.opened .Burger:after {
    transform: translateY(0) rotate(-45deg);
}

/* From: https://www.teamtownend.com/2013/07/ios-prevent-scrolling-on-body/ */
.StopScroll {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

@media only screen and (min-width: 990px) {
    .StopScroll {
        overflow: inherit;
    }

    .Nav {
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        height: inherit;
    }

    .closed .SettingsMenu,
    .closed .MainNavigation {
        display: inherit;
    }

    .MainNavigation,
    .SettingsMenu,
    .Nav,
    .MainHeader {
        background: var(--color-blue-darker);
    }

    .Nav > :nth-child(1) {
        order: 1;
    }

    .Nav > :nth-child(2) {
        order: 3;
        margin-left: auto;
        /* Push to right */
    }

    .Nav > :nth-child(3) {
        order: 2;
    }

    .MainNavigation {
        padding: 0;
        display: flex;
        flex-direction: row;
        height: inherit;
        overflow-y: inherit;
    }

    .SettingsMenu {
        height: inherit;
        padding: var(--s1);
    }

    .MainHeader {
        font-size: 1rem;
        line-height: 1.1;
        border-right: 1px solid var(--color-blue);
        border-bottom: none;
        padding: var(--s-2) 0;
        width: 122px;
        text-align: center;
        height: var(--main-nav-height);
        position: relative;
    }

    .MainHeader > span,
    .MainHeader > a {
        margin: 0 auto;
    }

    .MainHeaderExpanded:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        border: 8px solid transparent;
        border-bottom: 8px solid var(--color-blue-dark);
        margin-left: -8px;
        z-index: 11;
    }

    .MainHeader > *[aria-current='location']:after {
        content: '';
        width: 60%;
        height: 5px;
        position: absolute;
        left: 20%;
        bottom: 0;
        background: var(--color-red);
    }

    .SubHeader {
        font-size: 1.6rem;
        font-weight: 400;
        max-width: 20ch;
    }

    .Drawer {
        position: absolute;
        z-index: 777;
        top: var(--main-nav-height);
        left: 0;
        width: 100vw;
        background-color: var(--color-blue-dark);
        margin: 0;
        padding: var(--s4);
        box-shadow: var(--shadow-elevation-high);
        overflow-y: auto;
        overscroll-behavior-y: contain;
        max-height: 100vh;
    }

    @supports (-webkit-touch-callout: none) {
        .Drawer {
            /* HACK For Safari only (otherwise, this breaks on desktop Chromium browsers) */
            max-height: -webkit-fill-available;
        }
    }

    .DrawerClosed {
        max-height: inherit;
        /* Needed because we use .visually-hidden instead of display: none */
    }

    .Arrow {
        display: none;
    }

    .BurgerContainer {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .MainHeader {
        width: inherit;
        min-width: 150px;
        max-width: 220px;
        padding: 0 var(--s0);
    }
}

@media only screen and (min-width: 1500px) {
    .MainHeader {
        min-width: 200px;
        max-width: 300px;
        padding: 0 var(--s1);
    }
}
