.Container {
    --border: 1px solid white;

    border-top: var(--border);

    &:last-child {
        border-bottom: var(--border);
    }

    & > summary {
        position: relative;
        cursor: pointer;
        list-style: none;
        background-color: black;
        color: white;
        font-weight: 700;
        padding: 1em 3em 1em 1em;

        /* set new background on focus */
        &:focus {
            background-color: #5d5b5d;
        }

        &::-webkit-details-marker {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 3em;
            background-image: url('../icons/add.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &[open] > summary::after {
        background-image: url('../icons/remove.svg');
    }
}
